import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createFileSystemAdapter = exports.FILE_SYSTEM_ADAPTER = void 0;
const fs = _fs;
exports.FILE_SYSTEM_ADAPTER = {
  lstat: fs.lstat,
  stat: fs.stat,
  lstatSync: fs.lstatSync,
  statSync: fs.statSync
};

function createFileSystemAdapter(fsMethods) {
  if (fsMethods === undefined) {
    return exports.FILE_SYSTEM_ADAPTER;
  }

  return Object.assign(Object.assign({}, exports.FILE_SYSTEM_ADAPTER), fsMethods);
}

exports.createFileSystemAdapter = createFileSystemAdapter;
export default exports;