var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.read = void 0;

function read(path, settings, callback) {
  settings.fs.lstat(path, (lstatError, lstat) => {
    if (lstatError !== null) {
      callFailureCallback(callback, lstatError);
      return;
    }

    if (!lstat.isSymbolicLink() || !settings.followSymbolicLink) {
      callSuccessCallback(callback, lstat);
      return;
    }

    settings.fs.stat(path, (statError, stat) => {
      if (statError !== null) {
        if (settings.throwErrorOnBrokenSymbolicLink) {
          callFailureCallback(callback, statError);
          return;
        }

        callSuccessCallback(callback, lstat);
        return;
      }

      if (settings.markSymbolicLink) {
        stat.isSymbolicLink = () => true;
      }

      callSuccessCallback(callback, stat);
    });
  });
}

exports.read = read;

function callFailureCallback(callback, error) {
  callback(error);
}

function callSuccessCallback(callback, result) {
  callback(null, result);
}

export default exports;