var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.read = void 0;

function read(path, settings) {
  const lstat = settings.fs.lstatSync(path);

  if (!lstat.isSymbolicLink() || !settings.followSymbolicLink) {
    return lstat;
  }

  try {
    const stat = settings.fs.statSync(path);

    if (settings.markSymbolicLink) {
      stat.isSymbolicLink = () => true;
    }

    return stat;
  } catch (error) {
    if (!settings.throwErrorOnBrokenSymbolicLink) {
      return lstat;
    }

    throw error;
  }
}

exports.read = read;
export default exports;