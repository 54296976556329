import _fs from "./adapters/fs";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
const fs = _fs;

class Settings {
  constructor(_options = {}) {
    this._options = _options;
    this.followSymbolicLink = this._getValue(this._options.followSymbolicLink, true);
    this.fs = fs.createFileSystemAdapter(this._options.fs);
    this.markSymbolicLink = this._getValue(this._options.markSymbolicLink, false);
    this.throwErrorOnBrokenSymbolicLink = this._getValue(this._options.throwErrorOnBrokenSymbolicLink, true);
  }

  _getValue(option, value) {
    return option !== null && option !== void 0 ? option : value;
  }

}

exports.default = Settings;
export default exports;